import { html, css, LitElement } from 'lit-element';
import { backdrop } from './styles';


 export class AppBackdrop extends LitElement {

  static get styles() {
  //  return [backdrop]
  }

  static get properties() {
    return {
      transparent: { type: Boolean },
      active: { type: Boolean },
      sticky: { type: Boolean }

    };
  }


  constructor() {
    super(...arguments);
    this.transparent = false;
    this.active = false;
    this.sticky = false;

  }






  render() {
    const { active, transparent, sticky} = this;


    return html`
    <style>
    :host {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 50px;
        box-sizing: border-box;
        z-index: 199
    }

    .backdrop,
    .backdrop:after {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0
    }

    .backdrop {
        overflow: hidden;
        pointer-events: none;
        opacity: 0;
        z-index: 101;
        /*-webkit-transition: opacity .3s;
        transition: opacity .3s*/
    }

    .backdrop:after {
        display: block;
        content: '';
        background: rgba(0, 0, 0, .7)
    }

    .backdrop[active] {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      /*  -webkit-transition: -webkit-transform opacity .3s cubic-bezier(.465, .183, .153, .946);
        transition: opacity .3s cubic-bezier(.465, .183, .153, .946)*/
    }

    .backdrop[transparent] {
        opacity: 0;
        cursor: default
    }

    </style>


<div class="backdrop" ?transparent="${transparent}" ?active="${active}" @click="${e => this._switchActiveState(e)}"></div>
    `;
  }


  _switchActiveState (e) {
    if(!this.sticky){
    const host= this.parentNode.host;
    host.active=false;
  }
  }



}
