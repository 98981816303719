import { LitElement, html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import '../../../../app-backdrop/app-backdrop.js';


export const renderBackdrop = (active, transparent, sticky) => active
? html`
<app-backdrop
?active="${active}"
?transparent="${transparent}"
?sticky="${sticky}"
>
</app-backdrop>

`
: null
